<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
    <path fill="currentColor"
          d="M15 15.26V13h-4l4-4h-3l3-3h-2l3-3l3 3h-2l3 3h-3l4 4h-4v2.76c2.13.71 3.68 1.91 4 3.24c-1.7-.13-3.38-.46-5-1c-1.21.61-2.63 1-4 1c-1.29 0-2.83-.42-4-1c-1.63.54-3.28.87-5 1c.54-2.23 4.4-4 9-4c1.05 0 2.06.09 3 .26M8 19s-3 1-6 1v2c3 0 6-1 6-1s2 1 4 1s4-1 4-1s3 1 6 1v-2c-3 0-6-1-6-1s-2 1-4 1s-4-1-4-1"></path>
  </svg>

</template>

<script>
export default {
  name: "IslandIcon"
};
</script>
