import gql from "graphql-tag";

export const Orders = {
  Queries: {
    shippingMethods: gql`
        query ShippingMethods {
            shippingMethods {
                id
                ExternalHtmlName
                ExternalHtmlDescription
                ExternalHtmlIcon
                Value
            }
        }
    `,
    Order: gql`
        query order($orderIdentifier: String) {
            order(orderIdentifier: $orderIdentifier) {
                id
                OrderIdentifier
                OrderNumber
                TotalShippingItemCosts
                TotalItemPrice
                TotalOrderDiscount
                OrderStatusTypeId
                PaymentMethodTypeId
                Customer {
                    id
                    Email
                }
                CustomerInvoiceAddress {
                    id
                    FirstName
                    LastName
                    Street1
                    HouseNo
                    City
                    ZipCode
                }
                CustomerAddress {
                    id
                    FirstName
                    LastName
                    Street1
                    HouseNo
                    City
                    ZipCode
                }
                ShippingProfileType {
                    id
                    ExternalHtmlName
                }
                PaymentMethodType {
                    id
                    ExternalHtmlName
                }
                OrderItems {
                    id
                    ItemId
                    SystemItemId
                    HostId
                    TotalPriceGross
                    PriceGross
                    TotalPriceNet
                    PriceNet
                    ArticleImageUrl
                    Quantity
                    Name
                    Item {
                        CustomAttributes {
                            Custom_Hotel_Name
                        }
                        Host {
                            Name
                        }
                    }
                }
                OrderTransactions {
                    id
                    PaymentRequestPostedAt
                    PaymentRequestObject
                    PaymentResponsePostedAt
                    PaymentConfirmationResponseObject
                    PaymentConfirmationAt
                    PaymentTransactionId
                    PaymentResponsePostedObject
                    PaymentTransactionLog
                    ThirdPartyOrderId
                    createdAt
                }
            }
        }
    `,
    OrderByOrderNumber: gql`
        query orderByOrderNumber($orderNumber: String) {
            orderByOrderNumber(orderNumber: $orderNumber) {
                id
                OrderIdentifier
                OrderNumber
                TotalShippingItemCosts
                TotalItemPrice
                TotalOrderDiscount
                OrderStatusTypeId
                PaymentMethodTypeId
                Customer {
                    id
                    Email
                }
                CustomerInvoiceAddress {
                    id
                    FirstName
                    LastName
                    Street1
                    HouseNo
                    City
                    ZipCode
                }
                CustomerAddress {
                    id
                    FirstName
                    LastName
                    Street1
                    HouseNo
                    City
                    ZipCode
                }
                ShippingProfileType {
                    id
                    ExternalHtmlName
                }
                PaymentMethodType {
                    id
                    ExternalHtmlName
                }
                OrderItems {
                    id
                    ItemId
                    HostId
                    TotalPriceGross
                    PriceGross
                    TotalPriceNet
                    PriceNet
                    ArticleImageUrl
                    Quantity
                    Name
                }
                OrderTransactions {
                    id
                    PaymentRequestPostedAt
                    PaymentRequestObject
                    PaymentResponsePostedAt
                    PaymentConfirmationResponseObject
                    PaymentConfirmationAt
                    PaymentTransactionId
                    PaymentResponsePostedObject
                    PaymentTransactionLog
                    ThirdPartyOrderId
                    createdAt
                }
            }
        }
    `,
    UserOrders: gql`
        query userOrders($timeRange: [Date]) {
            userOrders(timeRange: $timeRange) {
                id
                createdAt
                PaidAt
                OrderNumber
                TotalShippingItemCosts
                TotalItemPrice
                TotalOrderDiscount
                OrderStatusTypeId
                Customer {
                    id
                    Email
                }
                CustomerInvoiceAddress {
                    id
                    FirstName
                    LastName
                    Street1
                    HouseNo
                    City
                    ZipCode
                }
                CustomerAddress {
                    id
                    FirstName
                    LastName
                    Street1
                    HouseNo
                    City
                    ZipCode
                }
                ShippingProfileType {
                    id
                    ExternalHtmlName
                }
                OrderStatusType {
                    Name
                }
                PaymentMethodType {
                    id
                    ExternalHtmlName
                }
                OrderItems {
                    id
                    ItemId
                    HostId
                    TotalPriceGross
                    PriceGross
                    TotalPriceNet
                    PriceNet
                    ArticleImageUrl
                    Quantity
                    Name
                }
            }
        }
    `,
    UserOrder: gql`
        query userOrder($orderId: ID) {
            userOrder(orderId: $orderId) {
                id
                createdAt
                OrderNumber
                TotalShippingItemCosts
                TotalItemPrice
                TotalOrderDiscount
                Customer {
                    id
                    Email
                }
                CustomerInvoiceAddress {
                    id
                    FirstName
                    LastName
                    Street1
                    HouseNo
                    City
                    ZipCode
                }
                CustomerAddress {
                    id
                    FirstName
                    LastName
                    Street1
                    HouseNo
                    City
                    ZipCode
                }
                ShippingProfileType {
                    id
                    ExternalHtmlName
                }
                PaymentMethodType {
                    id
                    ExternalHtmlName
                }
                OrderItems {
                    id
                    ItemId
                    HostId
                    TotalPriceGross
                    PriceGross
                    TotalPriceNet
                    PriceNet
                    ArticleImageUrl
                    Quantity
                    Name
                }
            }
        }
    `,
    UserOrderDates: gql`
        query UserOrderDates {
            userOrderDates
        }
    `
  },
  Mutations: {
    cancelUserOrder: gql`
        mutation CancelUserOrder($id: ID!) {
            cancelUserOrder(id: $id) {
                IsSuccess
                Message
                StringData
            }
        }
    `
  }
};
