/**
 * @param {{
 *   src:string;
 * }} options
 */
export const showFullscreenImage = (options) => {
  const createDialog = () => {
    const dialog = document.createElement("dialog");
    dialog.id = "fullscreen-image-dialog";
    document.body.appendChild(dialog);
    return dialog;
  };
  const dialog = document.querySelector("#fullscreen-image-dialog") || createDialog();
  const img = document.createElement("img");
  img.src = options.src;
  img.className = "img-fluid w-100";
  dialog.innerHTML = "";
  dialog.appendChild(img);
  dialog.onclick = () => {
    return dialog.close();
  };
  dialog.showModal();
};
