<template lang="pug">
  Transition(name="shoppingBasket" @enter="showsContainer = true" @before-leave="showsContainer = false" @leave="$emit('close')")
    .shopping-basket-overlay(@click.self="close" v-if="showsOverlay")
      Transition(name="shoppingBasketContainer" @leave="showsOverlay = false")
        .shopping-basket-container(v-if="showsContainer")
          .top-bar.bg-rainbow.d-flex.align-items-center.justify-content-between.px-2.txt-white
            font-awesome-icon.icon(icon="shopping-cart")
            .h2.m-0 Warenkorb
            button.no-style.txt-white(@click="close")
              font-awesome-icon.icon(icon="times")
          .contents
            section.items
              ShoppingBasketItemList.p-3(@removeAll="removeAllItemsOfType($event)")
            section.shipping-method
              .p-3
                ShippingMethodSelector(:shipping-type-options="shippingTypeOptions" v-if="shippingTypeOptions && shippingTypeOptions.length")
                .h2(v-else)
                  .txt-td-grey-basic Versandart
                  TextContentLoader(:lines="5" :randomize="false")
            section.overview
              ShoppingBasketOverview.p-3
            section.checkout
              .px-3.pt-3.pb-2
                .h2 Kauf abschließen
                .btn-block.button.button-action.button-tdays(@click="checkout") Zur Kasse
            section.payment-method
              .px-3
                .d-block.d-md-flex.align-items-center.justify-content-stretch
                  button.btn-block.button.button-secondary.button-klarna.mt-0.mt-md-2(@click="handleKlarnaCheckout" title="Klarna")
                    .klarna-logo
                  button.btn-block.button.button-secondary.button-paypal.mx-0.mx-md-2(@click="handlePaypalCheckout" style="height:43px;" title="PayPal")
                    font-awesome-icon(icon="fa-brands fa-paypal")
                  button.btn-block.button.button-secondary.button-amazon(@click="handleAmazonCheckout" title="Amazon Pay")
                    .amazon-logo
                span.extra-info.pt-2.d-block.justify.small
                  | Durch Klicken auf &bdquo;Klarna&quot;, &bdquo;PayPal&quot; oder &bdquo;amazon pay&quot; stimme ich den&nbsp;
                  a.txt-tdays-lighter(href="/agb?ref=from_hsb" target="_blank") AGB
                  | &nbsp;von touriDat GmbH & Co. KG zu. Ich best&auml;tige die&nbsp;
                  a.txt-tdays-lighter(href='/datenschutz?ref=from_hsb', target='_blank') Datenschutzerkl&auml;rung
                  | &nbsp;sowie die Belehrung &uuml;ber das&nbsp;
                  a.txt-tdays-lighter(href='/widerrufsrecht?ref=from_hsb', target='_blank') Widerrufsrecht
                  | &nbsp;der touriDat GmbH &amp; Co. KG gelesen zu haben und stimme diesen zu. Als Kunde von touriDat erhalten Sie Produktempfehlungen per Mail, von denen Sie sich jederzeit &uuml;ber einen Link in der Mail abmelden k&ouml;nnen.
</template>
<script>
import AccordionSelector from "@/views/components/accordionSelector/AccordionSelector.vue";
import AccordionSelectorOption from "@/views/components/accordionSelector/partials/AccordionSelectorOption.vue";
import ShippingMethodSelector from "@/views/components/shoppingBasket/partials/ShippingMethodSelector.vue";
import ShoppingBasketItemList from "@/views/components/shoppingBasket/partials/ShoppingBasketItemList.vue";
import ShoppingBasketOverview from "@/views/components/shoppingBasket/partials/ShoppingBasketOverview.vue";
import { getCheckoutData } from "@/lib/helper/checkout";
import { CheckoutComponent } from "@/lib/components/checkout/CheckoutComponent.ts";
import { sendAddPaymentInfo, sendBeginCheckout, sendViewCart } from "@/lib/helper/gtm";
import EventBus from "@/event-bus";
import config from "@/config/config";
import TextContentLoader from "@/views/components/partials/contentLoader/TextContentLoader.vue";
import { loadShippingMethods } from "@/lib/helper/shipping-methods";

export default {
  name: "ShoppingBasket",
  components: {
    TextContentLoader,
    ShoppingBasketOverview,
    ShoppingBasketItemList,
    ShippingMethodSelector,
    AccordionSelector,
    AccordionSelectorOption
  },
  data() {
    return {
      showsOverlay: false,
      showsContainer: false,
      shippingTypeOptions: []
    };
  },
  computed: {
    numberOfItems() {
      return this.$shoppingBasket.items.length;
    }
  },
  watch: {
    numberOfItems(value) {
      if (value === 0) this.close();
    }
  },
  async mounted() {
    this.shippingTypeOptions = await loadShippingMethods(this.$apollo);
    this.showsOverlay = true;
    document.body.classList.add("no-scroll");
    sendViewCart(
      this.$shoppingBasket.uniqueItems,
      this.$shoppingBasket.overallCost
    );
  },
  destroyed() {
    document.body.classList.remove("no-scroll");
  },
  methods: {
    async removeAllItemsOfType(item) {
      await this.$shoppingBasket.removeItem(item, item.Quantity);
    },
    checkout() {
      this.$router.push({ name: "Checkout" });
      this.$emit("close");
    },
    getCheckoutData() {
      return getCheckoutData(this);
    },
    close() {
      this.showsContainer = false;
    },
    async handleAmazonCheckout() {
      try {
        EventBus.$emit("changeLoadingState", true);
        const { items, voucherCode, shippingType, referrerId } =
          this.getCheckoutData();
        const checkoutComponent = new CheckoutComponent();
        const payload = await checkoutComponent.getAmazonPayload(
          this.$apollo,
          items,
          voucherCode,
          shippingType,
          referrerId
        );
        if (!payload) {
          EventBus.$emit("changeLoadingState", false);
          return this.$alert("Es konnte kein Amazon-Payload geladen werden.");
        }
        const amazonButtonSignature =
          await checkoutComponent.getAmazonButtonSignature(
            this.$apollo,
            payload
          );
        if (!amazonButtonSignature) {
          EventBus.$emit("changeLoadingState", false);
          return this.$alert(
            "Es konnte keine Amazon Button-Signatur geladen werden."
          );
        }
        let buttonWrapper = document.querySelector("#AmazonPayButton");
        if (buttonWrapper) buttonWrapper.parentNode.removeChild(buttonWrapper);
        // create a new button
        buttonWrapper = document.createElement("div");
        buttonWrapper.id = "AmazonPayButton";
        buttonWrapper.setAttribute("style", "display:none!important;");
        document.body.appendChild(buttonWrapper);
        // @ts-ignore
        const renderButton = window.amazon?.Pay?.renderButton;
        if (!renderButton) {
          return this.$alert(
            "Es konnte kein AmazonPay-Button gefunden werden."
          );
        }
        renderButton("#AmazonPayButton", {
          // set checkout environment
          merchantId: "ANK039XDJ1LGI",
          publicKeyId: config.external.amazon.publicKeyId,
          ledgerCurrency: "EUR",
          // customize the buyer experience
          checkoutLanguage: "de_DE",
          productType: "PayAndShip",
          placement: "Cart",
          buttonColor: "LightGray",
          estimatedOrderAmount: {
            amount: this.$shoppingBasket.overallCost,
            currencyCode: "EUR"
          },
          sandbox: config.external.amazon.sandbox,
          // configure Create Checkout Session request
          createCheckoutSessionConfig: {
            payloadJSON: JSON.stringify(payload), // string generated in step 2
            signature: amazonButtonSignature // signature generated in step 3
          }
        });
        // trigger click on the button
        // @ts-ignore
        buttonWrapper.click();
        // remove the button
        buttonWrapper.parentNode.removeChild(buttonWrapper);
        sendAddPaymentInfo(
          this.$shoppingBasket.uniqueItems,
          this.$shoppingBasket.overallCost,
          "AmazonPay"
        );
        sendBeginCheckout(
          this.$shoppingBasket.uniqueItems,
          this.$shoppingBasket.overallCost,
          this.$shoppingBasket.discounts.length
            ? this.$shoppingBasket.discounts[0]?.id
            : undefined
        );
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    handleKlarnaCheckout() {
      EventBus.$emit("changeLoadingState", true);
      const { items, voucherCode, shippingType, referrerId } =
        this.getCheckoutData();

      const checkoutComponent = new CheckoutComponent();
      checkoutComponent.handleKlarnaCheckout(
        this.$apollo,
        items,
        voucherCode,
        shippingType,
        referrerId,
        (response) => {
          EventBus.$emit("changeLoadingState", false);
          const defaultError = `Es konnte kein Kauf bei Klarna angelegt werden.`;
          if (!response?.IsSuccess) {
            const errorMessage = response?.Message || defaultError;
            return this.$alert(errorMessage);
          }
          if (!response?.StringData) {
            return this.$alert(defaultError);
          }
          sendAddPaymentInfo(
            this.$shoppingBasket.uniqueItems,
            this.$shoppingBasket.overallCost,
            "Klarna"
          );
          sendBeginCheckout(
            this.$shoppingBasket.uniqueItems,
            this.$shoppingBasket.overallCost,
            this.$shoppingBasket.discounts.length
              ? this.$shoppingBasket.discounts[0]?.id
              : undefined
          );
          window.location = response.StringData;
        }
      );
    },
    handlePaypalCheckout() {
      EventBus.$emit("changeLoadingState", true);
      const { items, voucherCode, shippingType, referrerId } =
        this.getCheckoutData();
      const checkoutComponent = new CheckoutComponent();
      checkoutComponent.handlePaypalCheckout(
        this.$apollo,
        items,
        voucherCode,
        shippingType,
        referrerId,
        (response) => {
          EventBus.$emit("changeLoadingState", false);
          const defaultError = `Es konnte kein Kauf bei PayPal angelegt werden.`;
          if (!response?.IsSuccess) {
            const errorMessage = response?.Message || defaultError;
            return this.$alert(errorMessage);
          }
          if (!response?.StringData) {
            return this.$alert(defaultError);
          }
          sendAddPaymentInfo(
            this.$shoppingBasket.uniqueItems,
            this.$shoppingBasket.overallCost,
            "Paypal"
          );
          sendBeginCheckout(
            this.$shoppingBasket.uniqueItems,
            this.$shoppingBasket.overallCost,
            this.$shoppingBasket.discounts.length
              ? this.$shoppingBasket.discounts[0]?.id
              : undefined
          );
          window.location = response.StringData;
        }
      );
    }
  }
};
</script>
<style lang="scss"></style>
<style scoped lang="scss">
@import "@/assets/styles/_colors.scss";

.shopping-basket-overlay {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(1px);
  background-color: rgba($td-grey-darker, 0.25);

  .shopping-basket-container {
    top: 0;
    right: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    max-width: 576px;
    background-color: $page-bg;
    box-shadow: 0 0 25px rgba($td-grey-darker, 0.25);
    overflow: hidden;
    overflow-y: auto;

    .top-bar {
      height: 46px;

      .icon {
        font-size: 1.4rem;
      }
    }

    section .h2 {
      color: $td-grey-basic;
      margin-bottom: 1rem;
    }
  }
}
</style>
