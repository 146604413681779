import { stripSlashes } from "@/lib/helper/strings";
import { DeadEndUrls } from "@/graphql/DeadEndUrls.ts";

export const getCategoryName = (route) => {
  const path = stripSlashes(route.path);
  if (!path || !path.includes("/")) {
    return undefined;
  }
  return path.split("/").slice(1).join("/");
};

/**
 * Returns the "referrerId" query param value no matter the casing
 * @param {Record<string,any>} query
 * @returns {number}
 */
export const getReferrerIdParam = (query) => {
  const queryParams = {};
  Object.keys(query).forEach((key) => {
    if (key.toLowerCase() !== "referrerid") return;
    queryParams[key.toLowerCase()] = query[key];
  });
  if (queryParams.referrerid) return parseInt(queryParams.referrerid);
};
export const initializeSession = (query, session) => {
  if (typeof session?.set !== "function") throw new Error(`invalid session`);
  const refId = getReferrerIdParam(query);
  if (refId) {
    session.set("referrerId", refId);
  }

  let source = query.source || query.Source;
  if (source) {
    session.set("source", source);
  }

  let awinClick = query.awc;
  if (awinClick) {
    session.set("awc", awinClick);
  }
};

export const getReroute = async (apolloClient, sourceUrl) => {
  if (typeof apolloClient?.query !== "function") throw new Error(`apolloClient is not a valid ApolloClient`);
  if (typeof sourceUrl !== "string" || !sourceUrl.length) return;
  sourceUrl = decodeURIComponent(sourceUrl);
  const url = new URL(`https://www.touridat.com/${sourceUrl}`);
  sourceUrl = stripSlashes(url.pathname);
  return await apolloClient.query({
    query: DeadEndUrls.Queries.DeadEndUrl,
    variables: { sourceUrl }
  }).then(({ data }) => {
    const targetPath = data?.deadEndUrl?.RelativeDeadEndUrl;
    if (!targetPath?.length) return;
    return `/${stripSlashes(targetPath)}${url.search}${url.hash}`;
  });
};
