export default {
  7: "homeShield", // Hotelmarken
  8: "house", // Ferienwohnungen
  58: "group", // Single- & Gruppenreisen
  71: "vouchers", // Auswahlgutscheine
  92: "voucher", // Wertgutscheine
  93: "crown", // Luxusurlaub
  97: "hiking", // Luxusurlaub
  98: "wellness", // Aktivurlaub
  99: "heart", // Romantikurlaub
  648: "mountain", // In die Berge
  649: "island", // Landurlaub
  650: "food", // Schlemmerurlaub
  651: "city", // Städte-Trip
  652: "wave" // Urlaub am Wasser
};
