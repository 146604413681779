<template lang="pug">
  component.moloss-icon(:is="iconComponent")
</template>
<script>
import icons from "./icons";

export default {
  name: "MolossIcon",
  props: {
    icon: {
      type: String,
      required: true
    }
  },
  computed: {
    iconComponent() {
      const component = icons[this.icon];
      if (!component) throw new Error(`moloss-icon "${this.icon}" does not exist.`);
      return component;
    }
  }
};
</script>
<style scoped lang="scss">

</style>
