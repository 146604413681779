<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="1.1em" height="1.1em" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path
        d="M24 0v24H0V0zM12.594 23.258l-.012.002l-.071.035l-.02.004l-.014-.004l-.071-.036q-.016-.004-.024.006l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.016-.018m.264-.113l-.014.002l-.184.093l-.01.01l-.003.011l.018.43l.005.012l.008.008l.201.092q.019.005.029-.008l.004-.014l-.034-.614q-.005-.018-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.003-.011l.018-.43l-.003-.012l-.01-.01z"></path>
      <path fill="currentColor"
            d="M18.41 3.287a1 1 0 0 1-.233 1.395l-2.101 1.501a1.965 1.965 0 0 0-.762 2.089l3.343-3.343a1 1 0 1 1 1.414 1.414l-3.343 3.343a1.965 1.965 0 0 0 2.089-.762l1.501-2.101a1 1 0 0 1 1.627 1.162l-1.5 2.102a3.967 3.967 0 0 1-5.234 1.116l-1.736 1.736l4.803 4.804a1 1 0 0 1-1.414 1.414l-4.803-4.803l-4.718 4.717a1 1 0 1 1-1.414-1.414l4.717-4.718l-1.56-1.56l-1.414 1.414a1 1 0 0 1-1.414 0l-2.205-2.204a5 5 0 0 1-.936-5.772c.188-.376.403-.715.859-.79a1 1 0 0 1 .867.28L9.79 9.254l.004.004l2.268 2.268l1.736-1.736a3.967 3.967 0 0 1 1.116-5.233l2.102-1.501a1 1 0 0 1 1.395.232Z"></path>
    </g>
  </svg>
</template>

<script>
export default {
  name: "FoodIcon"
};
</script>
