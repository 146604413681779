<template lang="pug">
  .shipping-method-selector
    .h2.txt-td-grey-basic Versandart
    AccordionSelectorOption(v-for="shippingMethod in shippingTypeOptions" :selected="shippingTypeId == shippingMethod.id" :value="shippingMethod.id" @select="selectShippingType")
      template(v-slot:label)
        .d-flex.align-items-center
          .icon.aspect-square.d-flex.align-items-center.justify-content-center.h2.m-0(style="background-color:rgba(0,0,0,0.15);width:54px;")
            MolossIcon(:icon="getIcon(shippingMethod)")
          .d-flex.align-items-center.justify-content-between.w-100.px-3
            span {{ shippingMethod.ExternalHtmlName }}
            span(v-if="shippingMethod.Value") {{ shippingMethod.Value | formatPrice }}
      template(v-slot:default)
        .p-4.bg-white
          p.m-0 {{ shippingMethod.ExternalHtmlDescription }}
          .text-center.mt-4(v-if="shippingMethod.ExternalHtmlIcon")
            button.button.button-primary.button-tdays(@click="showFullscreenImage({src:shippingMethod.ExternalHtmlIcon})" v-if="shippingMethod.ExternalHtmlIcon && shippingMethod.ExternalHtmlIcon.length") Vorschau
</template>
<script>
import AccordionSelector from "@/views/components/accordionSelector/AccordionSelector.vue";
import AccordionSelectorOption from "@/views/components/accordionSelector/partials/AccordionSelectorOption.vue";
import { ShippingTypeEnum } from "@/utils/ShippingTypeEnum.ts";
import { sendAddShippingInfo } from "@/lib/helper/gtm";
import MolossIcon from "@/views/components/partials/MolossIcon.vue";
import { showFullscreenImage } from "@/lib/helper/dialog";
import { getIcon } from "@/lib/helper/shipping-methods";

export default {
  name: "ShippingMethodSelector",
  components: { MolossIcon, AccordionSelectorOption, AccordionSelector },
  props: {
    shippingTypeOptions: {
      type: Array,
      required: true
    }
  },
  computed: {
    shippingTypeId() {
      return this.$shoppingBasket.shipping?.id || ShippingTypeEnum.Email;
    }
  },
  methods: {
    showFullscreenImage,
    getIcon,
    getShippingTypeName(typeId) {
      switch (typeId) {
        default:
        case ShippingTypeEnum.Email:
          return "Email";
        case ShippingTypeEnum.Letter:
          return "Letter";
        case ShippingTypeEnum.Box:
          return "Box";
      }
    },
    selectShippingType(value) {
      const type = this.shippingTypeOptions.find(
        (shippingType) => shippingType.id == value
      );
      if (!type?.id) return;
      sendAddShippingInfo(
        this.$shoppingBasket.uniqueItems,
        type.Value,
        this.getShippingTypeName(parseInt(type.id))
      );
      this.$shoppingBasket.setShipping({
        id: type.id,
        value: type.Value
      });
    }
  }
};
</script>
<style scoped lang="scss"></style>
