import { Orders } from "@/graphql/Orders.ts";
import { useShippingMethodsStore } from "@/store/shipping-methods";

/**
 * @typeDef {{
 *   id:string | number;
 *   ExternalHtmlName?:string;
 *   ExternalHtmlDescription?:string;
 *   ExternalHtmlIcon?:string;
 *   Value:number;
 * }} ShippingMethod
 */
const icons = {
  1: "at", // E-Mail
  2: "envelope", // Designumschlag
  3: "box", // Geschenkbox
  4: "envelopeHeart", // Designumschlag - Für Dich
  5: "envelopeThumbsUp", // Designumschlag - Alles Gute
  6: "envelopeChristmas" // Designumschlag - Merry Christmas
};
/**
 * @param { ShippingMethod } shippingMethod
 * @returns string | undefined;
 */
export const getIcon = (shippingMethod) => {
  const id = typeof shippingMethod.id === "string" ? parseInt(shippingMethod.id) : shippingMethod.id;
  return icons[id];
};


/**
 * Query a list of Shipping Methods from the apollo server
 * @param { import("apollo-client").ApolloClient } apollo
 * @returns { Promise<ShippingMethod[]> }
 */
const useQuery = async (apollo) => {
  return await apollo.query({
    query: Orders.Queries.shippingMethods
  }).then((response) => response?.data?.shippingMethods || []);
};
/**
 * Retrieve a vue ref from the shippingMethods store if it has any - or query them from the server, store, and return them
 * @param { import("apollo-client").ApolloClient } apollo
 * @returns { Promise<import("vue").Ref<ShippingMethod[]>> }
 */
export const loadShippingMethods = async (apollo) => {
  const store = useShippingMethodsStore();
  const { set } = store;
  if (!store.shippingMethods?.value?.length) {
    set(await useQuery(apollo));
  }
  return store.shippingMethods;
};
