<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="1.4em" height="1.4em" viewBox="0 0 41.5 41.5">
    <path fill="currentColor" d="M36,24c-2.4,0-4.3,2-4.3,4.5c0-2.5-1.9-4.5-4.3-4.5s-4.3,2-4.3,4.5c0,5.6,7.5,11,8.6,11s8.6-5.4,8.6-11
					C40.3,25.1,38.4,24,36,24z" />
    <path fill="currentColor" d="M27.5,21.8c1.7,0,3.2,0.7,4.3,1.7c0.8-0.8,1.8-1.3,3-1.6V11L21.4,22.3c-0.2,0.2-0.4,0.2-0.6,0.2s0,0,0,0l0,0
					c-0.2,0-0.5,0-0.6-0.2L6.7,11v19.7c0,0.5,0.5,1,1,1h14.2c-0.4-1.1-0.7-2.3-0.7-3.4C21.2,24.7,24,21.8,27.5,21.8z" />
    <polygon fill="currentColor" points="33.2,9.7 20.7,9.7 8.3,9.7 20.7,20.3" />
  </svg>
</template>

<script>
export default {
  name: "EnvelopeHeartIcon"
};
</script>
