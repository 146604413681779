import { render, staticRenderFns } from "./ShoppingBasket.vue?vue&type=template&id=33a55439&scoped=true&lang=pug"
import script from "./ShoppingBasket.vue?vue&type=script&lang=js"
export * from "./ShoppingBasket.vue?vue&type=script&lang=js"
import style1 from "./ShoppingBasket.vue?vue&type=style&index=1&id=33a55439&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33a55439",
  null
  
)

export default component.exports