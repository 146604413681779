<template lang="pug">
  ul(:data-level="level")
    li(:data-level="level" v-for="node in nodes")
      .d-flex.align-items-center
        MolossIcon(:icon="getCategoryIcon(node.id)" v-if="getCategoryIcon(node.id)")
        router-link.label(v-if="node.Url" :to="getRoute(node.Url)") {{node.Name}}
      TreeMenu(:nodes="node.Children" :level="level + 1" :base-path="basePath" :ref-type="refType")
</template>

<script>

import MolossIcon from "@/views/components/partials/MolossIcon.vue";
import categoryIcons from "@/lib/category-icons";

export default {
  name: "TreeMenu",
  components: { MolossIcon },
  props: {
    nodes: Array,
    level: Number,
    refType: String,
    basePath: String
  },
  methods: {
    getRoute(path) {
      const route = { path: this.getFullPath(path) };
      if (this.refType) route.query = { ref: this.refType };
      return route;
    },
    getFullPath(path) {
      return this.basePath + path;
    },
    getCategoryIcon(categoryId) {
      return categoryIcons[categoryId];
    }
  }
};
</script>

<style scoped></style>
