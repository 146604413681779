import { defineStore } from "pinia";
import { ref } from "vue";

export const useShippingMethodsStore = defineStore("shippingMethods", () => {
  const shippingMethods = ref(undefined);
  const set = (value) => {
    shippingMethods.value = value;
  };

  return { shippingMethods, set };
});
