<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 56 56">
    <path fill="currentColor" fillRule="evenodd"
          d="M3 20.279V16a4 4 0 0 1 4-4h42a4 4 0 0 1 4 4v4.45a3.2 3.2 0 0 1-1.95 2.945a5.001 5.001 0 0 0 .046 9.23A3.08 3.08 0 0 1 53 35.469V40a4 4 0 0 1-4 4H7a4 4 0 0 1-4-4v-4.263c0-1.37.803-2.613 2.053-3.176a5.001 5.001 0 0 0-.01-9.126A3.46 3.46 0 0 1 3 20.28M39 23a3 3 0 1 0 0-6a3 3 0 0 0 0 6m0 8a3 3 0 1 0 0-6a3 3 0 0 0 0 6m0 8a3 3 0 1 0 0-6a3 3 0 0 0 0 6"></path>
  </svg>
</template>

<script>
export default {
  name: "VoucherIcon"
};
</script>
