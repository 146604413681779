<template lang="pug">
  .search-box.d-flex.justify-content-between.position-relative.align-items-stretch
    .position-relative.w-100
      input.w-100(type="text" enterkeyhint="search" :value="value" ref="searchInput" @focus="handleFocus" @blur="handleBlur" @input="$emit('input',$event.target.value)" :placeholder="placeholder" @keydown.enter.prevent="handleEnterKey" @keydown.down.prevent="navigateSuggestionsUp" @keydown.up.prevent="navigateSuggestionsDown" autocomplete="off")
      .position-absolute(v-if="showLoadingIndicator" style="font-size:2rem;opacity:0.3;right:1rem;top:50%;line-height:0;transform: translateY(-50%);")
        ThreeDotsAnimation
    button.button.button-primary.button-tdays.d-none.d-md-block(@click="submit(value)" :disabled="!enableEmpty && !canSubmit")
      font-awesome-icon.icon(icon="search")
    .suggestions.position-absolute.w-100.bg-white(v-show="value.trim().length > 0 && showSuggestions && suggestions.length > 0 && isFocused")
      .suggestion.p-3.txt-td-grey-basic(v-for="(html,index) in matchedSuggestions" @mouseenter="selectedSuggestionIndex=index" @mouseleave="selectedSuggestionIndex=undefined" @click="submitSelectedSuggestion" v-html="html" :class="{selected:selectedSuggestionIndex === index}")
</template>

<script>
import { sendSearch } from "@/lib/helper/gtm";
import ThreeDotsAnimation from "@/views/components/loading/ThreeDotsAnimation.vue";

export default {
  name: "SearchBox",
  components: { ThreeDotsAnimation },
  emits: ["input", "change", "submit", "focus", "blur"],
  props: {
    value: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: "z. B. Berlin, Allgäu oder Nordsee"
    },
    showSuggestions: {
      type: Boolean,
      default: true
    },
    showLoadingIndicator: Boolean,
    suggestions: {
      type: Array,
      default: () => []
    },
    enableEmpty: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isFocused: false,
      selectedSuggestionIndex: undefined
    };
  },
  computed: {
    canSubmit() {
      return this.value?.trim().length > 0;
    },
    matchedSuggestions() {
      const term = this.value?.trim()?.toLowerCase() || "";
      return this.suggestions.map((suggestion) => {
        return suggestion.replaceAll(term, `<b class="txt-themed-lighter">${term}</b>`);
      });
    }
  },
  methods: {
    clearSuggestions() {
      //this.suggestions = [];
      this.selectedSuggestionIndex = undefined;
    },
    submit(value) {
      if (!this.selectedSuggestionIndex && this.suggestions?.length) {
        value = this.suggestions[0];
      }
      sendSearch(value);
      this.$emit("input", value);
      this.$emit("submit", value);
      this.$refs.searchInput.blur();
      this.isFocused = false;
    },
    submitSelectedSuggestion() {
      const suggestion = this.suggestions[this.selectedSuggestionIndex];
      if (!suggestion) return;
      this.submit(suggestion);
    },
    navigateSuggestionsUp() {
      if (this.selectedSuggestionIndex === undefined)
        this.selectedSuggestionIndex = -1;
      if (this.selectedSuggestionIndex + 1 > this.suggestions.length - 1) {
        this.selectedSuggestionIndex = 0;
        return;
      }
      this.selectedSuggestionIndex++;
    },
    navigateSuggestionsDown() {
      if (this.selectedSuggestionIndex === undefined)
        this.selectedSuggestionIndex = this.suggestions.length;
      if (this.selectedSuggestionIndex - 1 < 0) {
        this.selectedSuggestionIndex = this.suggestions.length - 1;
        return;
      }
      this.selectedSuggestionIndex--;
    },
    handleEnterKey() {
      if (!this.canSubmit) return;
      if (this.selectedSuggestionIndex === undefined) {
        this.submit(this.value);
        return;
      }
      this.submitSelectedSuggestion();
    },
    handleFocus(e) {
      this.isFocused = true;
      this.$emit("focus", e);
    },
    handleBlur(e) {
      setTimeout(() => {
        this.clearSuggestions();
        this.isFocused = false;
        this.$emit("blur", e);
      }, 400);
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/styles/_colors.scss";

.search-box {
  .suggestions {
    .match {
      font-weight: bold;
      color: $tdays-lighter;
    }
  }
}
</style>
<style lang="scss" scoped>
@import "@/assets/styles/_colors.scss";

.search-box {
  z-index: 2;
  transition: height 0.4s;

  input {
    border: 2px solid $td-grey-lighter;
    outline: none !important;
    transition: border-color 0.2s;
    color: $td-grey-dark;
    font-size: 16px; // needs to be >= 16px to prevent zooming on iOS devices
    padding-right: 4rem;

    &:hover {
      border-color: $td-grey-light;
    }

    &:focus,
    &:active {
      border-color: $td-grey-basic;
    }
  }

  input,
  .button {
    padding: clamp(1em, 1.5vw, 1.65em);
  }

  .button {
    .icon {
      padding: 0 0.35em;
    }
  }

  .suggestions {
    top: 100%;
    max-height: 75vh;
    overflow: hidden;
    overflow-y: auto;
    box-shadow: 0 0 5px rgba($td-grey-darker, 0.2);

    .suggestion {
      transition: background-color 0.4s;
      cursor: pointer;

      * {
        pointer-events: none !important;
      }

      &.selected {
        background-color: $td-grey-lighter;
      }
    }
  }
}
</style>
