import KlarnaIcon from "@/views/components/partials/icons/KlarnaIcon.vue";
import HikingIcon from "@/views/components/partials/icons/HikingIcon.vue";
import FoodIcon from "@/views/components/partials/icons/FoodIcon.vue";
import CrownIcon from "@/views/components/partials/icons/CrownIcon.vue";
import CowIcon from "@/views/components/partials/icons/CowIcon.vue";
import MountainIcon from "@/views/components/partials/icons/MountainIcon.vue";
import PawIcon from "@/views/components/partials/icons/PawIcon.vue";
import WellnessIcon from "@/views/components/partials/icons/WellnessIcon.vue";
import WaveIcon from "@/views/components/partials/icons/WaveIcon.vue";
import CityIcon from "@/views/components/partials/icons/CityIcon.vue";
import HomeShieldIcon from "@/views/components/partials/icons/HomeShieldIcon.vue";
import HouseIcon from "@/views/components/partials/icons/HouseIcon.vue";
import VoucherIcon from "@/views/components/partials/icons/VoucherIcon.vue";
import HeartIcon from "@/views/components/partials/icons/HeartIcon.vue";
import GroupIcon from "@/views/components/partials/icons/GroupIcon.vue";
import VouchersIcon from "@/views/components/partials/icons/VouchersIcon.vue";
import EnvelopeIcon from "@/views/components/partials/icons/EnvelopeIcon.vue";
import AtIcon from "@/views/components/partials/icons/AtIcon.vue";
import BoxIcon from "@/views/components/partials/icons/BoxIcon.vue";
import EnvelopeHeartIcon from "@/views/components/partials/icons/EnvelopeHeartIcon.vue";
import EnvelopeThumbsUpIcon from "@/views/components/partials/icons/EnvelopeThumbsUpIcon.vue";
import EnvelopeChristmasIcon from "@/views/components/partials/icons/EnvelopeChristmasIcon.vue";
import IslandIcon from "@/views/components/partials/icons/IslandIcon.vue";

export default {
  "klarna": KlarnaIcon,
  "hiking": HikingIcon,
  "cow": CowIcon,
  "crown": CrownIcon,
  "food": FoodIcon,
  "mountain": MountainIcon,
  "paw": PawIcon,
  "wave": WaveIcon,
  "wellness": WellnessIcon,
  "city": CityIcon,
  "house": HouseIcon,
  "homeShield": HomeShieldIcon,
  "voucher": VoucherIcon,
  "vouchers": VouchersIcon,
  "heart": HeartIcon,
  "group": GroupIcon,
  "envelope": EnvelopeIcon,
  "envelopeHeart": EnvelopeHeartIcon,
  "envelopeThumbsUp": EnvelopeThumbsUpIcon,
  "envelopeChristmas": EnvelopeChristmasIcon,
  "at": AtIcon,
  "box": BoxIcon,
  "island": IslandIcon
};
